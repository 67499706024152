<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
import { router } from "../router";

export default {
  name: "Logout",
  methods: {
    ...mapActions("account", ["logout"]),
  },
  created() {
    console.log('!!!')
    this.logout()
    router.push('/login')
  },
};
</script>
